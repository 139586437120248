import CalendarWorkTime from "./calendarWorkTime";
import TimePeriod from "./timePeriod";

const START_INTERSECTION = 1
const END_INTERSECTION = 2
const INCLUDE_INTERSECTION = 3

function splitInter(slotPeriod, intersectedPeriod, workTime) {
	
	const periodParts = intersectedPeriod.splitByPeriod(slotPeriod)
	const upperPart = periodParts[0]
	const lowerPart = periodParts[1]

	let period, type

	// Check can put the slot over and under the part of the busy period
	let tempPeriod = upperPart.copy()
	tempPeriod.shift(slotPeriod.duration * -1)
	const isAbleToPutOver = workTime.includesPeriod(tempPeriod)
	
	tempPeriod = lowerPart.copy()
	tempPeriod.shift(slotPeriod.duration)
	const isAbleToPutUnder = workTime.includesPeriod(tempPeriod)


	if (upperPart.duration < lowerPart.duration){
		if (isAbleToPutOver) {
			period = upperPart
			type = END_INTERSECTION
		} else if (isAbleToPutUnder) {
			period = lowerPart
			type = START_INTERSECTION
		}
	} 
	if (upperPart.duration >= lowerPart.duration){
		if (isAbleToPutUnder) {
			period = lowerPart
			type = START_INTERSECTION
		} else if (isAbleToPutOver) {
			period = upperPart
			type = END_INTERSECTION
		}
	}

	return  {
		period, type,
	}
}

/** 
 * Find the intersection of the slot with another slot
 * @param {TimePeriod} slotPeriod - desired time period after slot move
 * @param {Array<TimePeriod>} busyPeriods - array of busy time periods
 * @returns {Object<{period:TimePeriod, type:Number}>} - intersection
*/
function findIntersection(slotPeriod, busyPeriods){
	let type = undefined

	const period = busyPeriods.find(busyPeriod => {
		const startIntersect = busyPeriod.include(slotPeriod.start.date)
		const endIntersect = busyPeriod.include(slotPeriod.end.date) 

		if (startIntersect && endIntersect) {
			type = INCLUDE_INTERSECTION
		} else if (startIntersect) {
			type = START_INTERSECTION
		} else if (endIntersect) {
			type = END_INTERSECTION
		} else if (busyPeriod.isEqual(slotPeriod)) {
			type = START_INTERSECTION
		} else if (slotPeriod.include(busyPeriod.start.date)) {
			type = START_INTERSECTION
		} else if (slotPeriod.include(busyPeriod.end.date)) {
			type = END_INTERSECTION
		}
		return type != undefined
	})

	return {
		type, period
	}
}

function calcDeltaByType(type, slotPeriod, intersectedPeriod){
	if (type == START_INTERSECTION) {
		return (intersectedPeriod.end.time - slotPeriod.start.time) / 1000 / 60
	} else if (type == END_INTERSECTION) {
		return (intersectedPeriod.start.time - slotPeriod.end.time) / 1000 / 60	
	}
	return 0
}

/**
 * During the slot move if the slot is intersected with another slot,
 * this function provide calculation of the nearest free place to move the slot
 * @param {TimePeriod} slotPeriod - desired time period after slot move
 * @param {Array<TimePeriod>} busyPeriods - array of busy time periods
 * @param {CalendarWorkTime} workTime - work time bounds
 */
function calcNearestPlace(slotPeriod, busyPeriods, workTime){
	if (!slotPeriod || !busyPeriods || !workTime)
		return undefined
	
	if (!busyPeriods.length)
		return slotPeriod

	
	/**@type {TimePeriod} - nearest free place*/
	const nearestPlace = slotPeriod.copy()
	let {type, period} = findIntersection(slotPeriod, busyPeriods)

	// Time period is free
	if (!period || !type) {
		return slotPeriod
	}

	// 1.1. If the slot is included in the busy period
	// Split the period into parts and choose the shortest one
	if (type == INCLUDE_INTERSECTION) {
		const data = splitInter(slotPeriod, period, workTime)
		period = data.period
		if (!period) {
			return undefined
		}
	}


	// 2. Start first try of free space calculation
	const initParamsTop = {
		slotPeriod: slotPeriod,
		intersectedType: START_INTERSECTION,
		intersectedPeriod: period,
	}
	const deltaBot = searchPlace(initParamsTop, busyPeriods, workTime)
	
	const initParamsBot = {
		slotPeriod: slotPeriod,
		intersectedType: END_INTERSECTION,
		intersectedPeriod: period,
	}
	const deltaTop = searchPlace(initParamsBot, busyPeriods, workTime)
	
	// Smallest delta is the nearest place
	// console.log('delta result :>> ', {deltaBot, deltaTop});
	if (!deltaTop && !deltaBot) {
		return undefined
	} else if (deltaTop == undefined && deltaBot != undefined) {
		nearestPlace.shift(deltaBot)
	} else if (deltaBot == undefined && deltaTop != undefined) {
		nearestPlace.shift(deltaTop)
	} else if (Math.abs(deltaTop) < Math.abs(deltaBot)) {
		nearestPlace.shift(deltaTop)		
	} else {
		nearestPlace.shift(deltaBot)
	}

	return nearestPlace
}

/** 
 * Search the nearest free place to move the slot
 * @param {Object<{slotPeriod:TimePeriod, intersectedPeriod:TimePeriod, intersectedPeriod:Number}>} init - init values (contain slotPeriod, intersectedPeriod and intersectionType)
 * @param {Array<TimePeriod>} busyPeriods - array of busy time periods
 * @param {CalendarWorkTime} workTime - work time bounds
 * @returns {TimePeriod|undefined} - nearest free place
*/
function searchPlace(init, busyPeriods, workTime){
	const {
		slotPeriod:initPeriod,
		intersectedType:initIntersectedType,
		intersectedPeriod:initIntersectedBusyPeriod
	} = init

	const tempPeriod = initPeriod.copy()
	
	let intersectedType = initIntersectedType
	let intersectedPeriod = initIntersectedBusyPeriod
	let totalDelta = 0
	let delta = 0

	// console.log(`search ${intersectedType} :>> ${tempPeriod.toString()}`,);
	
	while (workTime.includesPeriod(tempPeriod)) {
		
		// Move the temp period lower that intersected period
		delta = calcDeltaByType(intersectedType, tempPeriod, intersectedPeriod)
		// console.log(`\t - temp period (${intersectedType}) :>> `, tempPeriod.toString(), ` + delta`, delta);
		tempPeriod.shift(delta)
		// console.log('\t - includes :>> ', workTime.includesPeriod(tempPeriod));
		if (!workTime.includesPeriod(tempPeriod)) {
			break
		}
		totalDelta += delta

		// Check this place is busy or not
		const {period, type} = findIntersection(tempPeriod, busyPeriods)

		// If it's free return temp period
		if (!period || !type) {
			return totalDelta
		}

		if (type == INCLUDE_INTERSECTION) {
			const intersection = splitInter(tempPeriod, period, workTime)
			intersectedPeriod = intersection.period
			intersectedType = intersection.type
			if (!intersectedPeriod || !intersectedType) {
				break
			}
		} else {
			intersectedPeriod = period
			intersectedType = initIntersectedType
		}
	}
	return undefined
}

export {
	calcNearestPlace,
}