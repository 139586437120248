import CMS from "../service/cms/service"
import SlotStorage from "./slotStorage"
import { convertTZ, isEqualDate, UTCString } from "./date"
import Slot from "./slot"
import SlotCollectionUsers from "./slotCollectionUsers"
import CalendarWorkTime from "./calendarWorkTime"

/**
 * Sorting fucn for the slots
 * @param {Slot} slotA - first slot
 * @param {Slot} slotB - second slot
 * @returns time difference between slots start date
 */
function sortByTime(slotA, slotB) {
	return slotA.period.start.time - slotB.period.start.time
}

function getCollectionIndex(){
	let index = 0
	return () => {
		index += 1
		return index
	}
}

const nextCollectionIndex = getCollectionIndex()


/**
 * Provide the collection united by any data (as an example date)
 */
class SlotCollection extends SlotStorage {
	
	loadingParams = null
	loadingCount = 0
	from = null
	to = null
	
	/**
	 * @param {Array} slots - array of slots
	 * @param {String} calendarUid - calendar uid
	 * @param {CalendarWorkTime} calendarUid - calendar work time
	 */
	constructor(calendarUid, slots, workTime) {
		super(slots, workTime)
		this.index = nextCollectionIndex()
		this.questionSlots = []
		this.calendarUid = calendarUid
		this.isLoaded = false
		this.users = new SlotCollectionUsers()
	}

	includes(slotId) {
		return !!this.findById(slotId)
	}

	/**
	 * Return the amount of the question slots
	 * @returns 
	 */
	questionLength(){
		return this.questionSlots.length
	}

	neighborByStart(slot, {hour, minute}) {
		const neighbor = this.getByDate(slot.period.start.date)
			.filter(s => s.index != slot.index)
			.find(neighbor => {
				return neighbor.period.start.date.getHours() == hour &&
					neighbor.period.start.date.getMinutes() == minute &&
					neighbor.index != slot.index
			})
		
		if (!neighbor)
			return undefined

		return neighbor
	}

	neighborByEnd(slot, {hour, minute}) {
		const neighbor = this.getByDate(slot.period.start.date)
			.filter(s => s.index != slot.index)
			.find(neighbor => {
				return neighbor.period.end.date.getHours() == hour &&
						neighbor.period.end.date.getMinutes() == minute &&
						neighbor.index != slot.index
			})

		if (!neighbor)
			return undefined

		return neighbor
	}

	/**
	 * Update the slot list of a collection
	 * @param {Date} from - from date
	 * @param {Date} to - to date 
	 * @param {Object} browserTZ - timezone of the client browser
	 * @param {Object} calendarTZ - timezone of the current calendar 
	 * @param {boolean} [cleanOld=false] - clean old slots from collection
	 */
	updateSlots(from, to, browserTZ, calendarTZ, cleanOld=false) {
		this.loadingParams = {from, to, browserTZ, calendarTZ}
		return CMS.slots.getAll(this.calendarUid, from, to)
		.then(data => {
			if (data && data.err)
				throw data.err

			const slots = []

			if (data.slots) {
				data.slots.forEach(slotInfo => {
					if (this.includes(slotInfo.id) && !cleanOld)
						return


					const date = new Date(slotInfo.startAt)
					const dateInCalendarTZ = convertTZ(date, browserTZ, calendarTZ)
					const dur = slotInfo.dur

					delete slotInfo.startAt
					delete slotInfo.dur
					
					slots.push(new Slot(dateInCalendarTZ, dur, slotInfo))
				})
			}

			this.add(slots, cleanOld)
			this.loadingCount += 1
			if (data.users) {
				const usersData = Object.keys(data.users)
				.map(key => {
					return {id: Number(key), name: data.users[key]}
				})
				this.users.addAttenders(usersData)
			}

			if (data.hosts) {
				const hostsData = Object.keys(data.hosts)
				.map(key => {
					return {id: Number(key), name: data.hosts[key]}
				})
				this.users.addHosts(hostsData)
			}

			return slots
		})
		.catch(err => {
			console.log(err)
		})
		.finally(() => {
			this.isLoading = false
			this.isLoaded = true
		})
	}

	/**
	 * Make filter of the slots by date and other support options
	 * @param {Date} date - date
	 * @param {Object} opt - other support options
	 * @returns array of slots by date
	 */
	getByDate(date, opt={
		withQuestionSlots: false,
		sortByTime: false,
	}) {
		
		const slots = this.findByDate(date)
		if (opt.withQuestionSlots) {
			slots.push(this.questionSlots.filter(slot => {
				return isEqualDate(slot.period.start.date, date)
			}))
		}

		if (opt.sortByTime) {
			return slots.sort(sortByTime)
		}

		return slots
	}

	getById(slotId) {
		return this.findById(slotId)
	}	

	loadingParamsIsEqual(from, to, browserTZ, calendarTZ){
		if (this.loadingParams == null)
			return false

		return isEqualDate(this.loadingParams.from, from)
			&& isEqualDate(this.loadingParams.to, to)
			&& this.loadingParams.browserTZ.id == browserTZ.id
			&& this.loadingParams.calendarTZ.id == calendarTZ.id
	}

	/**
	 * Make filter of the slots by range and other support options
	 * @param {Date} from from date
	 * @param {Date} to to date
	 * @param {Object} opt - other support options
	 * @returns array of slots by range
	 */
	getByRange(from, to, opt={
		withQuestionSlots: false,
		sortByTime: false,
	}) {
		const slots = this.findByRange(from, to)

		if (opt.withQuestionSlots) {
			slots.push(this.questionSlots.filter(slot => {
				return slot.period.start.date >= from &&
						slot.period.start.date <= to
			}))
		}

		if (opt.sortByTime) {
			return slots.sort(sortByTime)
		}

		return slots
	}

	getQuestionsByDate(date, opt={
		sortByTime: false,
	}) {

		let result = this.questionSlots
		.filter(slot => isEqualDate(date, slot.period.start.date))

		if (opt.sortByTime) {
			result = result.sort(sortByTime)
		}

		return result
	}

	updateSlotTime(slot, time, browserTZ, calendarTZ) {
		let slotId = slot.index
		let dateIsChanged = !isEqualDate(slot.period.start.date, time)
		let convertedTime = convertTZ(time, calendarTZ, browserTZ)
		return CMS.slots.patch(this.calendarUid, slotId, {startAt: UTCString(convertedTime)})
		.then(slotData => {
			if (slotData.err){
				throw slotData.err
			}
			
			if (dateIsChanged) {
				this.replaceSlot(slot, time)
			} else {
				slot.period.update(time)
			}
			if (dateIsChanged)
				this.resortSlots(time)
			this.resortSlots(slot.period.start.date)
			
			this.loadingCount += 1

			return time
		}).catch(err => {
			console.log(err)
			return err
		})
	}

	submitSlot(slot, browserTZ, calendarTZ, isProCalendar) {

		let submitData = slot.toSubmitData(isProCalendar)

		submitData.startAt = convertTZ(submitData.startAt, calendarTZ, browserTZ)
		submitData.startAt = UTCString(submitData.startAt)
		submitData.timezoneId = calendarTZ.id
		if (slot.title)
			submitData.title = slot.title

		return CMS.slots.upload(this.calendarUid, submitData)
		.then((newSlots) => {          
			if (newSlots.err){
				throw newSlots.err
			}   
	
			if (!newSlots || newSlots.length == 0) 
				return
			let slots = newSlots.map(slotInfo => {
				let date = new Date(slotInfo.startAt)
				date = convertTZ(date, browserTZ, calendarTZ)
				return new Slot(date, slotInfo.dur, slotInfo)
			}) 

	
			this.addSlots(slots)

			return slots
		}).finally(() => {
			if (slot.settings.isQuestionSlot) {
				this.removeQuestionSlot(slot)
			}
		})
	}

	addQuestionSlot(questionSlot){
		this.questionSlots.push(questionSlot)
	}

	removeQuestionSlot(questionSlot){
		this.questionSlots = this.questionSlots.filter(slot => slot !== questionSlot)
	}

	addSlots(slots){
		if (!slots || (slots instanceof Array && slots.length == 0))
			return

		const newSlots = []
		if (slots instanceof Array) {
			newSlots.push(...slots)
		} else if (slots instanceof Slot) {
			newSlots.push(slots)
		}
		this.add(newSlots)
		this.loadingCount += 1
	}

	replaceSlot(slot, newDate) {
		if (!slot)
			return false 
		
		this.remove([slot])
		slot.period.update(newDate)
		this.add([slot])

		return true
	}

	removeSlot(slot){
		return CMS.slots.delete(this.calendarUid, slot.index)
		.then(data => {
			if (data.err)
				throw data.err
			this.remove([slot])
		})
	}
}

export default SlotCollection